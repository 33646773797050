import React, { useEffect, useState } from 'react'

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../firebase/Firebase";

function Icon({ style, name }) {
    return (
        <span icon={true} className={`${name} ${style}`}></span>
        // <span icon="true" name={name.replace("mdi mdi-", "")} source="MaterialCommunityIcons" className={`${name} ${style}`} ></span>
    );
}
const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

export const CustomLeftArrow = ({ onClick }) => {
    return (
        <button
            onClick={(e) => {
                e.stopPropagation();
                onClick()
            }}
            className=" absolute left-5 w-10 h-10 bg-white rounded"
        >
            <Icon name="mdi mdi-chevron-left" style="text-2xl" />
        </button>
    );
};

export const CustomRightArrow = ({ onClick }) => {
    return (
        <button
            onClick={(e) => {
                e.stopPropagation();
                onClick()
            }}
            className=" absolute right-5 w-10 h-10 bg-white rounded"
        >
            <Icon name="mdi mdi-chevron-right" style="text-2xl" />
        </button>
    );
};

export default function SingleRoom() {
    const [product, setProduct] = useState({});
    const { productId } = useParams();
    const [show, setShow] = useState(false)

    useEffect(() => {
        const fetchProductById = async () => {
            try {
                const productRef = doc(db, 'products', productId); // Reference to the specific product document
                const productSnap = await getDoc(productRef);
                if (productSnap.exists()) {
                    // Check if the product document exists
                    console.log(productSnap.data())
                    setProduct({ id: productSnap.id, ...productSnap.data() });
                } else {
                    console.log('No such product document!');
                }
            } catch (error) {
                console.error('Error fetching product: ', error);
            }
        };
        fetchProductById();
    }, [productId]);
    return (
        <>
            {
                show &&
                <div className='h-[100vh] w-[100vw] bg-[rgba(0,0,0,.5)] fixed z-50 top-0 grid'
                    onClick={(e) => {
                        e.stopPropagation()
                        setShow(false)
                    }}
                >
                    {/* <div className='absolute top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%]'> */}
                    {/* <div className='h-full grid'> */}
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        className=''
                        containerClass="carousel-container"
                        dotListClass="custom-dot-list-style"
                        itemClass="w-full"
                        customLeftArrow={<CustomLeftArrow />}
                        customRightArrow={<CustomRightArrow />}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        {
                            product?.image?.length > 0 ?
                                product?.image?.map((image) => (
                                    <>
                                        <img
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            key={image}
                                            src={image}
                                            alt="product"
                                            // className="w-full aspect-[2.45] rounded-2xl"
                                            className="w-full aspect-[.9]  md:aspect-[2.45] rounded-2xl"
                                        />
                                    </>
                                ))
                                :
                                <></>
                        }

                    </Carousel>
                    {/* </div> */}
                </div>
            }
            <div className=" max-w-screen-2xl mx-auto px-4 mt-4">
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    clas
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="w-full"
                    customLeftArrow={<CustomLeftArrow />}
                    customRightArrow={<CustomRightArrow />}
                >
                    {
                        product?.image?.length > 0 ?
                            product?.image?.map((image) => (
                                <>
                                    <img
                                        onClick={() => {
                                            setShow(true)
                                        }}
                                        key={image}
                                        src={image}
                                        alt="product"
                                        className="w-full aspect-[2.45] rounded-2xl"
                                    />
                                </>
                            ))
                            :
                            <></>
                    }

                </Carousel>
                <p class=" font-normal text-sm leading-6 text-gray-500 mt-10">For: <span className="text-gray-800  font-medium">Girls</span></p>
                <div className="flex flex-wrap lg:flex-nowrap gap-6 mt-10">
                    <div className="lg:w-[70%]">
                        <div className=" ">
                            <p className="text-2xl font-semibold text-gray-700">Services</p>
                            <div className="grid mt-6 gap-4 space-y-6 justify-center xs:grid-cols-2 sm:grid-cols-3">
                                <div className="flex items-center gap-2 sm:mx-auto w-52">
                                    <Icon
                                        name="mdi mdi-air-conditioner"
                                        style="text-5xl"
                                    />
                                    <p className="font-medium text-xl">
                                        AC Available
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 sm:mx-auto w-52">
                                    <Icon
                                        name="mdi mdi-camera-wireless-outline"
                                        style="text-5xl"
                                    />
                                    <p className="font-medium text-xl">
                                        CCTV
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 sm:mx-auto w-52">
                                    <Icon
                                        name="mdi mdi-food-takeout-box-outline"
                                        style="text-5xl"
                                    />
                                    <p className="font-medium text-xl">
                                        Hygienic Food
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 sm:mx-auto w-52">
                                    <Icon
                                        name="mdi mdi-wardrobe-outline"
                                        style="text-5xl"
                                    />
                                    <p className="font-medium text-xl">
                                        Wardrobe with locker
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 sm:mx-auto w-52">
                                    <Icon
                                        name="mdi mdi-wifi"
                                        style="text-5xl"
                                    />
                                    <p className="font-medium text-xl">
                                        WiFi
                                    </p>
                                </div>
                                <div className="flex items-center gap-2 sm:mx-auto w-52">
                                    <Icon
                                        name="mdi mdi-shield-account-outline"
                                        style="text-5xl"
                                    />
                                    <p className="font-medium text-xl">
                                        Security
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="mt-24">
                            <p className=" text-2xl font-semibold text-gray-700">
                                Details:-
                            </p>
                            <p className=" text-font_size font-medium mt-6">
                                {product?.description}
                            </p>
                        </div>
                        <div className="mt-24">
                            <p className="text-2xl font-semibold text-gray-700 mb-2">
                                Rules of the PG:-
                            </p>
                            <ul class="list-disc px-5 text-xl space-y-2 font-medium tracking-tighter leading-normal letter-spacing-0.2">
                                <li className="text-xl">Notice period(1 month).</li>
                                <li className="text-xl">One time Security deposit(adjustable on last month).</li>
                                <li className="text-xl">No shouting.</li>
                                <li className="text-xl">Maintain friendliness.</li>
                            </ul>
                        </div>
                    </div>
                    {
                        product?.category == "pg_1"
                            ?
                            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3428.6744512870728!2d76.7710570755813!3d30.755644774573238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQ1JzIwLjMiTiA3NsKwNDYnMjUuMSJF!5e0!3m2!1sen!2sin!4v1714190958591!5m2!1sen!2sin"
                                width="600" height="450"
                                allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            :
                            product?.category == "pg_2" ?
                                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3428.7496395333055!2d76.7751483755812!3d30.753533274574227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQ1JzEyLjciTiA3NsKwNDYnMzkuOCJF!5e0!3m2!1sen!2sin!4v1714191202244!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                                :
                                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3428.770188633938!2d76.77500257558114!3d30.752956174574614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQ1JzEwLjYiTiA3NsKwNDYnMzkuMyJF!5e0!3m2!1sen!2sin!4v1714191224724!5m2!1sen!2sin" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    }
                </div>
                <div className="h-44">
                </div>
            </div>
        </>
    )
}
