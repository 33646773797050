import React from 'react'
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './SingleRoom';


const Service = [
    {
        img: "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/pg11.jpeg?alt=media&token=1ff8d0d5-9bf5-48a9-bd3b-305d08a3d891",
        name: "Air Conditioner"
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/pg12.jpeg?alt=media&token=d60e836d-5399-4b97-a04b-1e4283a3f390",
        name: "Wifi"
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/pg53.jpeg?alt=media&token=001d9720-3c3b-4929-b0ed-11581113a0ec",
        name: "Washing Machine"
    },
    {
        img: "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/pg22.jpeg?alt=media&token=2b6d7226-fdc6-4fc3-b868-0f2c0a16f96b",
        name: "Geyser Service"
    },
]
export default function CommonArea() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    return (
        <div className='my-8'>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                // autoPlay={true}
                className=""
                autoPlaySpeed={1000}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
            >
                {
                    Service.map((data) => (
                        <div className=''>
                            {/* <img src={data?.img} className='aspect-[2] w-full object-cover' /> */}
                            <img src={data?.img} className='aspect-[2] lg:aspect-[3.5] w-full object-cover' />
                            {/* <p className='bg-[rgba(0,0,0,.3)] tracking-widest text-gray-200 w-full h-full flex items-center justify-center text-xl font-bold absolute top-0 group-hover:opacity-100 lg:opacity-0 duration-700'>{data.name}</p> */}
                        </div>
                    ))
                }
            </Carousel>
        </div>
    )
}
