import React from 'react'
import { Link } from 'react-router-dom';

function Icon({ style, name }) {
  return (
    <span icon={true} className={`${name} ${style}`}></span>
    // <span icon="true" name={name.replace("mdi mdi-", "")} source="MaterialCommunityIcons" className={`${name} ${style}`} ></span>
  );
}
function Footer() {
  return (

    <footer class="">
      <div class="  border-t-2 border-purple-100 mx-auto w-full max-w-screen-2xl p-4 py-6 lg:py-8">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
            <a
              class="flex items-center tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl "
              href="/"
            >
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50"
                className="mr-2"
              >
                <path d="M 24.962891 1.0546875 A 1.0001 1.0001 0 0 0 24.384766 1.2636719 L 1.3847656 19.210938 A 1.0005659 1.0005659 0 0 0 2.6152344 20.789062 L 4 19.708984 L 4 46 A 1.0001 1.0001 0 0 0 5 47 L 18.832031 47 A 1.0001 1.0001 0 0 0 19.158203 47 L 30.832031 47 A 1.0001 1.0001 0 0 0 31.158203 47 L 45 47 A 1.0001 1.0001 0 0 0 46 46 L 46 19.708984 L 47.384766 20.789062 A 1.0005657 1.0005657 0 1 0 48.615234 19.210938 L 41 13.269531 L 41 6 L 35 6 L 35 8.5859375 L 25.615234 1.2636719 A 1.0001 1.0001 0 0 0 24.962891 1.0546875 z M 25 3.3222656 L 44 18.148438 L 44 45 L 32 45 L 32 26 L 18 26 L 18 45 L 6 45 L 6 18.148438 L 25 3.3222656 z M 37 8 L 39 8 L 39 11.708984 L 37 10.146484 L 37 8 z M 20 28 L 30 28 L 30 45 L 20 45 L 20 28 z"></path>
              </svg>
              KakkarPGServices
            </a>
            <p className=' max-w-xs text-gray-400 font-medium text-base mt-6'>
             Kakkar PG started their journey in 2013. We opened up this with a mindset of providing full satisfying homestay services with healthy food and a positive family friendly environment. We treat students, working professionals all with our healthy mindset and healthy environment. Owner of PG Mr. Rahul Kakkar is the founder and owner with a very polite and humble nature and is responsible for providing secure services of home stay.</p>
          </div>
          <div class="grid grid-cols-1 mt-10 sm:grid-cols-2 md:w-1/2 gap-8 sm:gap-6">
            <div>
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase">Usefull links</h2>
              <ul class="text-gray-400 font-medium">
                <li class="mb-1">
                  <Link to="/" class="hover:underline">Home</Link>
                </li>
                <li class="mb-1">
                  <Link to="/Rooms" class="hover:underline">Room</Link>
                </li>
                {/* <li className='mb-1'>
                  <Link to="/" class="hover:underline">Facilities</Link>
                </li> */}
                <li className='mb-1'>
                  <Link to="/AboutUs" class="hover:underline">About Us</Link>
                </li>
                {/* <li className='mb-1'>
                  <Link to="/" class="hover:underline">Gallery</Link>
                </li> */}
                <li className='mb-1'>
                  <Link to="/Contact" class="hover:underline">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercas">Contact us</h2>
              <ul class="text-gray-400 font-medium">
                <li class="mb-4 flex items-center gap-2 ">
                  <Icon name="mdi mdi-map-marker-radius" style="text-3xl" />
                  <p class="hover:underline ">342, Sector 15A, chandigarh 160003.</p>
                </li>
                <li class="mb-4 flex items-center gap-2 ">
                  <Icon name="mdi mdi-phone-outline" style="text-3xl" />
                  <a class="hover:underline cursor-pointer" href="tel:6283029505">6283029505</a>
                </li>
                <li class="mb-4 flex items-center gap-2 ">
                  <Icon name="mdi mdi-phone-outline" style="text-3xl" />
                  <a class="hover:underline cursor-pointer" href="tel:6283029505">9877017470</a>
                </li>
                <li class="mb-4 flex items-center gap-2 ">
                  <Icon name="mdi mdi-email-edit-outline" style="text-3xl" />
                  <p class="hover:underline ">Pg Feel like a RahulKakkar54267@gmail.com </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="sm:flex bg-gray-800 text-white sm:items-center py-6 sm:justify-between">
        <div className='sm:flex sm:items-center sm:justify-between w-full max-w-screen-2xl mx-auto px-4'>
          <a to="/" class="hover:underline  font-medium">Pg Copyright © 2023 All Rights Reserved</a>
          <div class="flex mt-4 sm:justify-center sm:mt-0">
            <a href="https://www.facebook.com/share/kBXuqHLrwFd5WEzD/?mibextid=qi2Omg" class="">
              <Icon name="mdi mdi-facebook" style="text-2xl" />
              <span class="sr-only">Facebook page</span>
            </a>
            <a href="https://www.instagram.com/kakkarpgservices" class="  ms-5">
              <Icon name="mdi mdi-instagram" style=" text-2xl" />
              <span class="sr-only">Discord community</span>
            </a>
            <a href="https://www.youtube.com/@kakkarpgservice1441" class=" ms-5">
              <Icon name="mdi mdi-youtube" style=" text-2xl" />
              <span class="sr-only">Twitter page</span>
            </a>
          </div>
        </div>
      </div>
    </footer>

  )
}

export default Footer
