import React, { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/Firebase";
import { Link } from "react-router-dom";


const generateMockProducts = (numProducts) => {
    const products = [];
    for (let i = 1; i <= numProducts; i++) {
        products.push({
            id: i,
            imageUrl: `https://via.placeholder.com/400?text=Product+${i}`,
            productName: `Product ${i}`,
            price: `${(Math.random() * 100).toFixed(2)}`, // Generates a random price between 0 and 100
        });
    }
    return products;
};

const mockProducts = generateMockProducts(20);

function Rooms() {
    const [products, setProducts] = useState([]);
    const [prevProducts, setPrevProducts] = useState([]);
    const [filter, setFilter] = useState({
        price: 10000
    });

    useEffect(() => {
        // Function to fetch data from Firestore
        const fetchData = async () => {
            try {
                const productSnapshot = await getDocs(collection(db, 'products'));
                const productData = productSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                console.log(productData)
                setProducts(productData);
                setPrevProducts(productData);
            } catch (error) {
                console.error('Error fetching products: ', error);
            }
        };

        // Call the fetch data function
        fetchData();
    }, []);
    return (
        <>

            <section class="py-4 relative ">
                <div class="w-full mx-auto px-4 md:px-8">
                    {/* <div class="flex  lg:items-center max-lg:gap-4 lg:justify-end justify-center w-full">

                        <div class="relative w-full max-w-sm">
                            <svg class="absolute top-1/2 -translate-y-1/2 left-4 z-50" width="20" height="20"
                                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M16.5555 3.33203H3.44463C2.46273 3.33203 1.66675 4.12802 1.66675 5.10991C1.66675 5.56785 1.84345 6.00813 2.16004 6.33901L6.83697 11.2271C6.97021 11.3664 7.03684 11.436 7.0974 11.5068C7.57207 12.062 7.85127 12.7576 7.89207 13.4869C7.89728 13.5799 7.89728 13.6763 7.89728 13.869V16.251C7.89728 17.6854 9.30176 18.6988 10.663 18.2466C11.5227 17.961 12.1029 17.157 12.1029 16.251V14.2772C12.1029 13.6825 12.1029 13.3852 12.1523 13.1015C12.2323 12.6415 12.4081 12.2035 12.6683 11.8158C12.8287 11.5767 13.0342 11.3619 13.4454 10.9322L17.8401 6.33901C18.1567 6.00813 18.3334 5.56785 18.3334 5.10991C18.3334 4.12802 17.5374 3.33203 16.5555 3.33203Z"
                                    stroke="black" stroke-width="1.6" stroke-linecap="round" />
                            </svg>
                            <select id="Offer"
                                class="h-12 border border-gray-300 text-gray-900 pl-11 text-base font-normal leading-7 rounded-full block w-full py-2.5 px-4 appearance-none relative focus:outline-none bg-white transition-all duration-500 hover:border-gray-400 hover:bg-gray-50 focus-within:bg-gray-50">
                                <option selected>Sort by time(high to low)</option>
                                <option value="option 1">option 1</option>
                                <option value="option 2">option 2</option>
                                <option value="option 3">option 3</option>
                                <option value="option 4">option 4</option>
                            </select>
                            <svg class="absolute top-1/2 -translate-y-1/2 right-4 z-50" width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.0002 5.99845L8.00008 9.99862L3.99756 5.99609" stroke="#111827" stroke-width="1.6"
                                    stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                    </div> */}
                    <svg class="my-7 w-full" xmlns="http://www.w3.org/2000/svg" width="1216" height="2" viewBox="0 0 1216 2"
                        fill="none">
                        <path d="M0 1H1216" stroke="#E5E7EB" />
                    </svg>
                    {/* <div class=""> */}
                    <div class="grid grid-cols-12">
                        <div class="-z-1 lg:sticky lg:h-[100px] top-0 xl:top-10  xl:p-0 col-span-12 md:col-span-3 w-full max-md:max-w-md max-md:mx-auto">
                            {/* <div class="lg:sticky lg:h-[100px]  top-0 p-1 col-span-12 md:col-span-3 w-full max-md:max-w-md max-md:mx-auto"> */}
                            <div class="box rounded-xl border border-gray-300 bg-white p-6 w-full md:max-w-sm">
                                <h6 class="font-medium text-base leading-7 text-black mb-5">Price</h6>
                                <div class="flex flex-col items-start mb-5 gap-1">
                                    <label>₹{filter?.price}</label>
                                    <input type="range" className="w-full"
                                        min={1000} max={20000}
                                        value={filter?.price}
                                        onChange={(e) => {
                                            setFilter({
                                                ...filter,
                                                price: e.target.value
                                            })
                                            var filteredData = prevProducts?.filter(function (val, i, arr) {
                                                return val?.price < e.target.value
                                            });
                                            setProducts([...filteredData])
                                        }}
                                    />
                                </div>

                                <label for="countries" class="block mb-2 text-sm font-medium text-gray-600 w-full">Select PG </label>
                                <div class="relative w-full mb-8">
                                    <select id="FROM"
                                        class="h-12 border border-gray-300 text-gray-900 text-xs font-medium rounded-full block w-full py-2.5 px-4 appearance-none relative focus:outline-none bg-white"
                                        onChange={(e) => {
                                            if (e?.target?.value == "All") {
                                                delete filter["category"]
                                                return
                                            }
                                            setFilter({
                                                ...filter,
                                                category: e.target.value
                                            })
                                        }}
                                    >
                                        <option value="All" selected>Show All</option>
                                        <option value="pg_1">PG 1</option>
                                        <option value="pg_2">PG 2</option>
                                        <option value="pg_2">PG 3</option>
                                    </select>
                                    <svg class="absolute top-1/2 -translate-y-1/2 right-4 z-50" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 5.99845L8.00008 9.99862L3.99756 5.99609" stroke="#111827"
                                            stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <label for="countries" class="block mb-2 text-sm font-medium text-gray-600 w-full">Select Room Type </label>
                                <div class="relative w-full mb-8">
                                    <select id="FROM"
                                        class="h-12 border border-gray-300 text-gray-900 text-xs font-medium rounded-full block w-full py-2.5 px-4 appearance-none relative focus:outline-none bg-white"
                                        onChange={(e) => {
                                            if (e?.target?.value == "All") {
                                                delete filter["type"]
                                                return
                                            }
                                            setFilter({
                                                ...filter,
                                                type: e.target.value
                                            })
                                        }}
                                    >
                                        <option value="All" selected>Show All </option>
                                        <option value="single">Single Sharing</option>
                                        <option value="double">Double Sharing</option>
                                        <option value="triple">Triple Sharing</option>
                                        <option value="four">Four Sharing</option>
                                    </select>
                                    <svg class="absolute top-1/2 -translate-y-1/2 right-4 z-50" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 5.99845L8.00008 9.99862L3.99756 5.99609" stroke="#111827"
                                            stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <button
                                    onClick={() => {
                                        console.log(filter)
                                        let filteredData;
                                        if (filter?.category && filter?.type) {
                                            filteredData = prevProducts?.filter(function (val, i, arr) {
                                                return (val?.category == filter?.category) && (val?.type == filter?.type)
                                            });
                                        } else if (filter?.category) {
                                            filteredData = prevProducts?.filter(function (val, i, arr) {
                                                return (val?.category == filter?.category)
                                            });
                                        } else if (filter?.type) {
                                            filteredData = prevProducts?.filter(function (val, i, arr) {
                                                return (val?.type == filter?.type)
                                            });
                                        }
                                        else {
                                            setProducts(prevProducts)
                                            return
                                        }
                                        setProducts([...filteredData])
                                    }}
                                    class="w-full py-2.5 flex items-center justify-center gap-2 rounded-full bg-indigo-600 text-white font-semibold text-xs shadow-sm shadow-transparent transition-all duration-500 hover:bg-indigo-700 hover:shadow-indigo-200  ">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.4987 13.9997L13.1654 12.6663M13.832 7.33301C13.832 10.6467 11.1457 13.333 7.83203 13.333C4.51832 13.333 1.83203 10.6467 1.83203 7.33301C1.83203 4.0193 4.51832 1.33301 7.83203 1.33301C11.1457 1.33301 13.832 4.0193 13.832 7.33301Z"
                                            stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                    Search
                                </button>
                            </div>

                            {/* <div class="mt-7 box rounded-xl border border-gray-300 bg-white p-6 w-full md:max-w-sm">
                                <div class="flex items-center justify-between w-full pb-3 border-b border-gray-200 mb-7">
                                    <p class="font-medium text-base leading-7 text-black ">Filter Plans</p>
                                    <p
                                        class="font-medium text-xs text-gray-500 cursor-pointer transition-all duration-500 hover:text-indigo-600">
                                        RESET</p>
                                </div>


                                <div class="w-full mb-7">
                                    <div class='accordion-group grid grid-cols-1 gap-5 sm:gap-9'
                                        data-accordion="default-accordion">
                                        <div class='accordion '
                                            id='category-heading-one'>
                                            <button
                                                class='accordion-toggle group accordion-active:text-indigo-600 inline-flex items-center justify-between leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 active:text-indigo-600'
                                                aria-controls='category-collapse-one'>
                                                <h5 class="font-medium text-sm text-gray-900">
                                                    Availability
                                                </h5>
                                                <svg class='text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:rotate-180'
                                                    width='22' height='22' viewBox='0 0 22 22' fill='none'
                                                    xmlns='http://www.w3.org/2000/svg'>
                                                    <path
                                                        d='M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25'
                                                        stroke='currentColor' stroke-width='1.6' stroke-linecap='round'
                                                        stroke-linejoin='round'></path>
                                                </svg>

                                            </button>
                                            <div id='category-collapse-one'
                                                // class='accordion-content w-full px-0 overflow-hidden pr-4 max-h-0 '
                                                class='accordion-content w-full px-0 overflow-hidden pr-4 max-h-0 '
                                                aria-labelledby='category-heading-one'>

                                                <div class="box flex flex-col gap-2 mt-5">

                                                    <div class="flex items-center mb-2">
                                                        <input id="checkbox-option-1" type="checkbox" value="" class="checkbox-white w-5 h-5 appearance-none border border-gray-500  rounded mr-1 hover:border-indigo-100 hover:bg-indigo-600 checked:bg-no-repeat checked:bg-center checked:border-indigo-100 checked:bg-indigo-600 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                                        <label for="checkbox-option-1" class="ml-1 font-normal text-xs cursor-pointer  text-gray-600">option-1</label>
                                                    </div>
                                                    <div class="flex items-center mb-2">
                                                        <input id="checkbox-option-2" type="checkbox" value="" class="checkbox-white w-5 h-5 appearance-none border border-gray-500  rounded mr-1 hover:border-indigo-100 hover:bg-indigo-600 checked:bg-no-repeat checked:bg-center checked:border-indigo-600 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                                        <label for="checkbox-option-2" class="ml-1 font-normal text-xs cursor-pointer  text-gray-600">option-2</label>
                                                    </div>
                                                    <div class="flex items-center mb-2">
                                                        <input id="checkbox-option-3" type="checkbox" value="" class="checkbox-white w-5 h-5 appearance-none border border-gray-500  rounded mr-1 hover:border-indigo-600 hover:bg-indigo-600 checked:bg-no-repeat checked:bg-center checked:border-indigo-600 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                                        <label for="checkbox-option-3" class="ml-1 font-normal text-xs cursor-pointer  text-gray-600">option-3</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <label for="Offer" class="font-medium text-sm leading-6 text-gray-600 mb-1">Offer</label>
                                <div class="relative w-full mb-7">
                                    <select id="Offer"
                                        class="h-12 border border-gray-300 text-gray-900 text-xs font-medium rounded-full block w-full py-2.5 px-4 appearance-none relative focus:outline-none bg-white">
                                        <option selected>5% off upi discount</option>
                                        <option value="option 1">option 1</option>
                                        <option value="option 2">option 2</option>
                                        <option value="option 3">option 3</option>
                                        <option value="option 4">option 4</option>
                                    </select>
                                    <svg class="absolute top-1/2 -translate-y-1/2 right-4 z-50" width="16" height="16"
                                        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12.0002 5.99845L8.00008 9.99862L3.99756 5.99609" stroke="#111827"
                                            stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <p class="font-medium text-sm leading-6 text-black mb-3">Discount</p>
                                <div class="box flex flex-col gap-2">
                                    <div class="flex items-center">
                                        <input id="checkbox-default-1" type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                        <label for="checkbox-default-1" class="text-xs font-normal text-gray-600 leading-4 cursor-pointer">20% or more</label>
                                    </div>
                                    <div class="flex items-center">
                                        <input id="checkbox-default-2" type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                        <label for="checkbox-default-2" class="text-xs font-normal text-gray-600 leading-4 cursor-pointer">30% or more</label>
                                    </div>
                                    <div class="flex items-center">
                                        <input id="checkbox-default-3" type="checkbox" value="" class="w-5 h-5 appearance-none border border-gray-300  rounded-md mr-2 hover:border-indigo-500 hover:bg-indigo-100 checked:bg-no-repeat checked:bg-center checked:border-indigo-500 checked:bg-indigo-100 checked:bg-[url('https://pagedone.io/asset/uploads/1689406942.svg')]" />
                                        <label for="checkbox-default-3" class="text-xs font-normal text-gray-600 leading-4 cursor-pointer">50% or more</label>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div class="col-span-12 md:col-span-9 ">
                            <div className="container mx-auto flex items-center flex-wrap pt-4 pb-12">
                                <nav id="store" className="w-full z-30 top-0 px-6 py-1">
                                    <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
                                        <a
                                            className="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl"
                                            href="#"
                                        >
                                            Rooms
                                        </a>
                                    </div>
                                </nav>
                                <div className="min-h-[5    0vh] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4 ">
                                    {/* <div className="grid grid-cols-4"> */}
                                    {
                                        products.length > 0 ?
                                            products?.map((product) => (
                                                <Link to={`/Rooms/${product?.id}`}>
                                                    <div className="w-full  p-6 flex flex-col">
                                                        <a href="javascript:;" class="w-full rounded-xl shadow-xl mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                                                            <div class="rounded-t-xl">
                                                                <img src={product?.image[0]} alt="face cream image" class="w-full aspect-square rounded-t-3xl" />
                                                            </div>
                                                            <div class="mt-5 p-2">
                                                                <div class="flex items-center justify-between">
                                                                    <h6 class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">{product?.type} Sharing Room</h6>
                                                                </div>
                                                                <h6 class="font-semibold text-xl leading-8 text-gray-800">Price {product?.price} + {product?.security} Security</h6>
                                                                <p class=" font-normal text-sm leading-6 text-gray-500">Location: <span className="text-gray-800">
                                                                    {
                                                                        product.category == "pg_1" ?
                                                                            "Sector 15-A, Chandigarh, 160015"
                                                                            : product.category == "pg_2" ?
                                                                                "Sector 15B, Chandigarh"
                                                                                :
                                                                                "Sector 15B, Chandigarh"
                                                                    }
                                                                </span></p>
                                                                <div>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </Link>
                                            ))
                                            :
                                            <p className="text-xl font-medium ml-10 text-gray-700">No Rooms Available</p>
                                    }

                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default Rooms;
