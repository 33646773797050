import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CarouselItem from "../components/CarouselItem";
import ProductCard from "../components/ProductCard";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/Firebase";
import { Link } from "react-router-dom";
import { CustomLeftArrow, CustomRightArrow } from "./SingleRoom";
import Menu from "./Menu";
import Services from "./Services";
import CommonArea from "./CommonArea";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const carouselData = [
  {
    id: "carousel-2",
    imageSrc:
      "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/WhatsApp%20Image%202024-04-29%20at%203.49.00%20PM.jpeg?alt=media&token=0c7b0c49-d2f8-454f-9f40-a3bbbeae82ba",
    productName: "Real Bamboo Wall Clock",
    productLink: "#",
  },
  {
    id: "carousel-2",
    imageSrc:
      "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/WhatsApp%20Image%202024-04-29%20at%203.49.00%20PM%20(1).jpeg?alt=media&token=bb549f74-f031-41f0-a371-2b0488384fb2",
    productName: "Real Bamboo Wall Clock",
    productLink: "#",
  },
  {
    id: "carousel-2",
    imageSrc:
      "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/pg_out2.jpeg?alt=media&token=819a4510-ae7f-4cf6-be93-1afa691c6e2d",
    productName: "Real Bamboo Wall Clock",
    productLink: "#",
  },
  {
    id: "carousel-1",
    imageSrc:
      // "https://images.unsplash.com/photo-1422190441165-ec2956dc9ecc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1600&q=80",
      "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/pg_out1.jpeg?alt=media&token=32a5f685-0351-4987-bb6d-94105d71750b",
    productName: "Stripy Zig Zag Jigsaw Pillow and Duvet Set",
    productLink: "#",
  },
  {
    id: "carousel-3",
    imageSrc:
      "https://firebasestorage.googleapis.com/v0/b/pg-database-89762.appspot.com/o/pg_out3.jpeg?alt=media&token=2d9471f6-0275-47d0-af81-0c5056fadce1",
    productName: "Brown and blue hardbound book",
    productLink: "#",
  },
];

const generateMockProducts = (numProducts) => {
  const products = [];
  for (let i = 1; i <= numProducts; i++) {
    products.push({
      id: i,
      imageUrl: `https://via.placeholder.com/400?text=Product+${i}`,
      productName: `Product ${i}`,
      price: `${(Math.random() * 100).toFixed(2)}`, // Generates a random price between 0 and 100
    });
  }
  return products;
};

const mockProducts = generateMockProducts(6);

function Home() {

  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Function to fetch data from Firestore
    const fetchData = async () => {
      try {
        const productSnapshot = await getDocs(collection(db, 'products'));
        const productData = productSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setProducts(productData);
      } catch (error) {
        console.error('Error fetching products: ', error);
      }
    };

    // Call the fetch data function
    fetchData();
  }, []);

  console.log(products);

  return (
    <>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        className=""
        autoPlaySpeed={1000}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        customLeftArrow={<CustomLeftArrow />}
        customRightArrow={<CustomRightArrow />}
      >
        {carouselData.map((item) => (
          <CarouselItem
            key={item.id}
            imageSrc={item.imageSrc}
            productName={item.productName}
            productLink={item.productLink}
          />
        ))}
      </Carousel>
      <section className="bg-white py-8">
        <div className="container mx-auto flex items-center flex-wrap pt-4 pb-12">
          <nav id="store" className="w-full z-30 top-0 px-6 py-1">
            <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3">
              <a
                className="uppercase tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-xl"
                href="/"
              >
                Rooms
              </a>
              <div className="flex items-center " id="store-nav-content">
                <Link to="/Rooms">
                  <button className="px-4 py-2 text-white font-medium bg-gray-800 rounded-full hover:bg-gray-700 hover:scale-105 duration-500">View More</button>
                </Link>
              </div>
            </div>
          </nav>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  xl:grid-cols-4">
            {products?.filter((item, index) => index < 8)?.map((product) => (
              <Link to={`/Rooms/${product?.id}`}>
                <div className="w-full  p-6 flex flex-col">
                  <a href="javascript:;" class="w-full rounded-xl shadow-xl mx-auto sm:mr-0 group cursor-pointer lg:mx-auto bg-white transition-all duration-500">
                    <div class="rounded-t-xl">
                      <img src={product?.image[0]} alt="face cream image" class="w-full aspect-square rounded-t-3xl" />
                    </div>
                    <div class="mt-5 p-2">
                      <div class="flex items-center justify-between">
                        <h6 class="font-semibold text-xl leading-8 text-black transition-all duration-500 group-hover:text-indigo-600">{product?.type} Sharing Room</h6>
                      </div>
                      <h6 class="font-semibold text-xl leading-8 text-gray-800">Price {product?.price} + {product?.security} Security</h6>
                      <p class=" font-normal text-sm leading-6 text-gray-500">Location: <span className="text-gray-800">
                        {
                          product.category == "pg_1" ?
                            "Sector 15-A, Chandigarh, 160015"
                            : product.category == "pg_2" ?
                              "Sector 15B, Chandigarh"
                              :
                              "Sector 15B, Chandigarh"
                        }
                      </span></p>
                      <div>
                      </div>
                    </div>
                  </a>
                </div>
              </Link>
            ))}
          </div>
          <hr className="w-screen my-6 mb-10" />
        </div>
        <div className="underline text-center text-4xl md:text-5xl text-gray-700 font-bold">
          <h6>Common Area</h6>
        </div>
        <CommonArea />
        <hr className="w-screen my-6 mb-10" />
        <div className="underline text-center text-4xl md:text-5xl text-gray-700 font-bold">
          <h6>Food & Menu</h6>
        </div>
        <Menu />

        <div className="underline text-center text-4xl md:text-5xl text-gray-700 font-bold">
          <h6>Our Services</h6>
        </div>
        <Services />

        <hr className="w-screen my-6 mb-10" />


        <Review />
        <hr className="w-screen my-10" />


        <div className="underline text-center text-xl lg:text-4xl text-gray-700 font-bold mb-4">
          <h6>Running project of New PG's in Chandigarh,Panchkula and Mohali</h6>
        </div>
        <div className="p-5 flex items-center justify-center">
        <img className="lg:aspect-[2] rounded-xl" src="https://amazingarchitecture.com/storage/4500/1-residence_239_chandigarh_design_three_sixty.jpg" />
        </div>
        <hr className="w-screen mt-10" />
        <FAQ />

        <div className="underline text-center text-4xl md:text-5xl text-gray-700 font-bold mb-8">
          <h6>PG's Location</h6>
        </div>
        <div className="flex items-center justify-evenly p-4 h-[50vh] md:flex-row flex-col">
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3428.6744512870728!2d76.7710570755813!3d30.755644774573238!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQ1JzIwLjMiTiA3NsKwNDYnMjUuMSJF!5e0!3m2!1sen!2sin!4v1714190958591!5m2!1sen!2sin"
            className="w-full lg:w-1/3 h-[100%]"
            allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3428.7496395333055!2d76.7751483755812!3d30.753533274574227!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQ1JzEyLjciTiA3NsKwNDYnMzkuOCJF!5e0!3m2!1sen!2sin!4v1714191202244!5m2!1sen!2sin" className="w-full lg:w-1/3 h-[100%] mt-4 lg:mt-0 lg:mx-4" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3428.770188633938!2d76.77500257558114!3d30.752956174574614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQ1JzEwLjYiTiA3NsKwNDYnMzkuMyJF!5e0!3m2!1sen!2sin!4v1714191224724!5m2!1sen!2sin" className="w-full lg:w-1/3 h-[100%] mt-4 lg:mt-0 " allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>



        </div>
        <hr className="w-screen my-6 mb-10" />
      </section >
    </>
  );
}

export default Home;


const Testimonial = () => {
  return (
    <section class="text-neutral-700 ">
      <div class="mx-auto text-center md:max-w-xl lg:max-w-3xl">
        <h3 class="mb-6 text-3xl font-bold">Testimonials</h3>
        <p class="mb-6 pb-2 md:mb-12 md:pb-0">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit,
          error amet numquam iure provident voluptate esse quasi, veritatis
          totam voluptas nostrum quisquam eum porro a pariatur veniam.
        </p>
      </div>

      <div class="grid gap-6 text-center md:grid-cols-3">
        {
          [...new Array(3)]?.map((item, i) => (
            <div key={i}>
              <div
                class="block rounded-lg bg-gray-800 shadow-2xl ">
                <div class="h-28 overflow-hidden rounded-t-lg bg-[#9d789b]"></div>
                {/* <div class="h-28 overflow-hidden rounded-t-lg bg-gray-700"></div> */}
                <div
                  class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white">
                  <img
                    src="https://tecdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp" />
                </div>
                <div class="p-6">
                  <h4 class="mb-4 text-2xl font-semibold text-white">Maria Smantha</h4>
                  <hr />
                  <p class="mt-4 text-white">
                    <span class="inline-block pe-2 [&>svg]:w-5"
                    ><svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 448 512">
                        <path
                          d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z" />
                      </svg>
                    </span>
                    Lorem ipsum dolor sit amet eos adipisci, consectetur
                    adipisicing elit.
                  </p>
                </div>
              </div>
            </div>
          ))
        }

      </div>
    </section>
  )
}


const Review = () => {
  return (
    // <section class="py-24 w-full">
    <div class="w-full px-[10%]">
      {/* <h6
        class="text-3xl font-semibold text-indigo-600 mb-5 lg:text-left"
      >
        Testimonials
      </h6> */}
      <div
        class="w-full flex flex-col justify-center items-center  flex-wrap md:flex-wrap lg:flex-nowrap lg:flex-row lg:justify-between ">
        <div class="w-full lg:w-[30%] text-center ">
          <h2 class="text-4xl font-bold text-gray-900 leading-[3.25rem] mb-8">Customers gave their <span
            class=" text-transparent bg-clip-text bg-gradient-to-tr from-indigo-600 to-violet-600">Feedback</span>
          </h2>

        </div>
        <div class="w-[100%] lg:w-[50%] lg:block  items-center justify-center ">
          <Carousel
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
                slidesToSlide: 1, // optional, default to 1.
              },
            }}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            className=""
            autoPlaySpeed={1000}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style text-red-500"
            itemClass="carousel-item-padding-40-px"
          >
            <div class="swiper-slide group bg-white border border-solid border-gray-300 rounded-2xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-indigo-600">
              <div class="flex items-center gap-5 mb-5 sm:mb-9">
                {/* <img src="https://pagedone.io/asset/uploads/1696229969.png" alt="avatar" /> */}
                <div class="grid gap-1">
                  <h5 class="text-gray-900 font-medium transition-all duration-500  ">Riya jain</h5>
                  <span class="text-sm leading-6 text-gray-500">Studen </span>
                </div>
              </div>
              <div
                class="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500  ">
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <p
                class="text-sm text-gray-500 leading-6 transition-all duration-500 min-h-24  group-hover:text-gray-800">
                The location of Comfort Living PG Hotel is excellent. It's centrally located with easy access to public transportation, shopping centers, and restaurants.
              </p>

            </div>
            <div
              class="swiper-slide group bg-white border border-solid border-gray-300 rounded-2xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-indigo-600">
              <div class="flex items-center gap-5 mb-5 sm:mb-9">
                {/* <img src="	https://pagedone.io/asset/uploads/1696229994.png" alt="avatar" /> */}
                <div class="grid gap-1">
                  <h5 class="text-gray-900 font-medium transition-all duration-500  ">Harshika Purwar
                  </h5>
                  <span class="text-sm leading-6 text-gray-500">Studen </span>
                </div>
              </div>
              <div
                class="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500  ">
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <p
                class="text-sm text-gray-500 leading-6 transition-all duration-500 min-h-24 group-hover:text-gray-800">
               The room I stayed in was clean and comfortable, with a cozy bed, ample storage space, and a small desk. The common areas were well-maintained, including a spacious lounge area and a fully equipped kitchen.
              </p>

            </div>
            <div
              class="swiper-slide group bg-white border border-solid border-gray-300 rounded-2xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-indigo-600">
              <div class="flex items-center gap-5 mb-5 sm:mb-9">
                {/* <img src="https://pagedone.io/asset/uploads/1696229969.png" alt="avatar" /> */}
                <div class="grid gap-1">
                  <h5 class="text-gray-900 font-medium transition-all duration-500  ">Divya kaur</h5>
                  <span class="text-sm leading-6 text-gray-500">Student </span>
                </div>
              </div>
              <div
                class="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500  ">
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <p
                class="text-sm text-gray-500 leading-6 transition-all duration-500 min-h-24 group-hover:text-gray-800">
                Safety was a top priority at Comfort Living PG Hotel. The building had secure access control, and I felt safe coming and going at any time of day. Additionally, the staff were diligent about maintaining security protocols.
              </p>

            </div>
            <div
              class="swiper-slide group bg-white border border-solid border-gray-300 rounded-2xl max-sm:max-w-sm max-sm:mx-auto p-6 transition-all duration-500 hover:border-indigo-600">
              <div class="flex items-center gap-5 mb-5 sm:mb-9">
                {/* <img src="	https://pagedone.io/asset/uploads/1696229994.png" alt="avatar" /> */}
                <div class="grid gap-1">
                  <h5 class="text-gray-900 font-medium transition-all duration-500  ">Shreya
                  </h5>
                  <span class="text-sm leading-6 text-gray-500">Student</span>
                </div>
              </div>
              <div
                class="flex items-center mb-5 sm:mb-9 gap-2 text-amber-500 transition-all duration-500  ">
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
                <svg class="w-5 h-5" viewBox="0 0 18 17" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.10326 1.31699C8.47008 0.57374 9.52992 0.57374 9.89674 1.31699L11.7063 4.98347C11.8519 5.27862 12.1335 5.48319 12.4592 5.53051L16.5054 6.11846C17.3256 6.23765 17.6531 7.24562 17.0596 7.82416L14.1318 10.6781C13.8961 10.9079 13.7885 11.2389 13.8442 11.5632L14.5353 15.5931C14.6754 16.41 13.818 17.033 13.0844 16.6473L9.46534 14.7446C9.17402 14.5915 8.82598 14.5915 8.53466 14.7446L4.91562 16.6473C4.18199 17.033 3.32456 16.41 3.46467 15.5931L4.15585 11.5632C4.21148 11.2389 4.10393 10.9079 3.86825 10.6781L0.940384 7.82416C0.346867 7.24562 0.674378 6.23765 1.4946 6.11846L5.54081 5.53051C5.86652 5.48319 6.14808 5.27862 6.29374 4.98347L8.10326 1.31699Z"
                    fill="currentColor"></path>
                </svg>
              </div>
              <p
                class="text-sm text-gray-500 leading-6 transition-all duration-500 min-h-24 group-hover:text-gray-800">
               One area where Comfort Living PG Hotel could improve is the quality and variety of the food provided. While breakfast was included and satisfactory, the dinner options were limited and didn't always meet my preferences. I often opted to dine out to have more choices.
              </p>

            </div>
          </Carousel>

        </div>
      </div>
    </div>
    // </section>
  )
}

const FAQ = () => {
  return (
    <section class="py-20">
      <div class="w-full px-[10%]">
        <h6
          class="text-3xl font-semibold text-indigo-600 mb-5 lg:text-left"
        >
          Frequently Asked Questions
        </h6>
        <div
          class="flex flex-col justify-center items-center gap-x-16 gap-y-5 xl:gap-28 lg:flex-row lg:justify-between max-lg:max-w-2xl mx-auto max-w-full"
        >
          <div class="w-full lg:w-1/2">
            <img
              src="https://images.unsplash.com/photo-1600585154526-990dced4db0d?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt="FAQ tailwind section"
              class="w-full aspect-square rounded-2xl shadow-2xl"
            />
          </div>
          <div class="w-full lg:w-1/2">
            <div class="lg:max-w-xl">
              <div class="mb-6 lg:mb-16">
                <h2
                  class="text-4xl text-center font-bold text-gray-900 leading-[3.25rem] mb-5 lg:text-left"
                >
                  Looking for answers?
                </h2>
              </div>
              <div class="accordion-group" data-accordion="default-accordion">
                <div
                  class="accordion pb-8 border-b border-solid border-gray-200 active"
                  id="basic-heading-one-with-arrow-always-open"
                >
                  <button
                    class="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium always-open"
                    aria-controls="basic-collapse-one-with-arrow-always-open"
                  >
                    <h5 >How to create an account?</h5>
                    {/* <svg
                      class="text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:rotate-180"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                        stroke="currentColor"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg> */}
                  </button>
                  <div
                    id="basic-collapse-one-with-arrow-always-open"
                    class="accordion-content w-full px-0 overflow-hidden pr-4 active max-h-[100px]"
                    // style="max-height: 100px;"
                    aria-labelledby="basic-heading-one-with-arrow-always-open"
                  >
                    <p class="text-base font-normal text-gray-600 ">
                      To create an account, find the 'Sign up' or 'Create
                      account' button, fill out the registration form with your
                      personal information, and click 'Create account' or 'Sign
                      up.' Verify your email address if needed, and then log in
                      to start using the platform.
                    </p>
                  </div>
                </div>
                <div
                  class="accordion py-8 border-b border-solid border-gray-200 "
                  id="basic-heading-two-with-arrow-always-open"
                >
                  <button
                    class="accordion-toggle group inline-flex items-center justify-between font-normal text-xl leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:font-medium"
                    aria-controls="basic-collapse-two-with-arrow-always-open"
                  >
                    <h5>Have any trust issue?</h5>
                    {/* <svg
                      class="text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:rotate-180"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                        stroke="currentColor"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg> */}
                  </button>
                  <div
                    id="basic-collapse-two-with-arrow-always-open"
                    class="accordion-content w-full px-0 overflow-hidden pr-4"
                    aria-labelledby="basic-heading-two-with-arrow-always-open"
                  // style=""
                  >
                    <p class="text-base text-gray-500 font-normal">
                      Our focus on providing robust and user-friendly content
                      management capabilities ensures that you can manage your
                      content with confidence, and achieve your content
                      marketing goals with ease.
                    </p>
                  </div>
                </div>
                <div
                  class="accordion py-8 border-b border-solid border-gray-200"
                  id="basic-heading-three-with-arrow-always-open"
                >
                  <button
                    class="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:font-medium accordion-active:text-indigo-600"
                    aria-controls="basic-collapse-three-with-arrow-always-open"
                  >
                    <h5>How can I reset my password?</h5>
                    {/* <svg
                      class="text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:rotate-180"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                        stroke="currentColor"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg> */}
                  </button>
                  <div
                    id="basic-collapse-three-with-arrow-always-open"
                    class="accordion-content w-full px-0 overflow-hidden pr-4"
                    aria-labelledby="basic-heading-three-with-arrow-always-open"
                  >
                    <p class="text-base text-gray-500 font-normal">
                      Our focus on providing robust and user-friendly content
                      management capabilities ensures that you can manage your
                      content with confidence, and achieve your content
                      marketing goals with ease.
                    </p>
                  </div>
                </div>
                <div
                  class="accordion py-8 "
                  id="basic-heading-four-with-arrow-always-open"
                >
                  <button
                    class="accordion-toggle group inline-flex items-center justify-between text-xl font-normal leading-8 text-gray-600 w-full transition duration-500 hover:text-indigo-600 accordion-active:font-medium accordion-active:text-indigo-600"
                    aria-controls="basic-collapse-four-with-arrow-always-open"
                  >
                    <h5>What is the payment process?</h5>
                    {/* <svg
                      class="text-gray-900 transition duration-500 group-hover:text-indigo-600 accordion-active:text-indigo-600 accordion-active:rotate-180"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.5 8.25L12.4142 12.3358C11.7475 13.0025 11.4142 13.3358 11 13.3358C10.5858 13.3358 10.2525 13.0025 9.58579 12.3358L5.5 8.25"
                        stroke="currentColor"
                        stroke-width="1.6"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg> */}
                  </button>
                  <div
                    id="basic-collapse-four-with-arrow-always-open"
                    class="accordion-content w-full px-0 overflow-hidden pr-4"
                    aria-labelledby="basic-heading-four-with-arrow-always-open"
                  >
                    <p class="text-base text-gray-500 font-normal">
                      Our focus on providing robust and user-friendly content
                      management capabilities ensures that you can manage your
                      content with confidence, and achieve your content
                      marketing goals with ease.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
