import React, { useState, useEffect } from 'react';
import { db } from '../firebase/Firebase';
import { collection, getDocs } from 'firebase/firestore';

function ContactSubmissions() {
  const [submissions, setSubmissions] = useState([]);
  const [showSubmissions, setShowSubmissions] = useState(false);

  useEffect(() => {
    if (showSubmissions) {
      const fetchSubmissions = async () => {
        try {
          const querySnapshot = await getDocs(collection(db, 'contactUs'));
          const submissionsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          console.log(submissionsData)
          setSubmissions(submissionsData);
        } catch (error) {
          console.error('Error fetching submissions: ', error);
        }
      };
      fetchSubmissions();
    }
  }, [showSubmissions]);

  useEffect(() => {
    const getUser = JSON.parse(sessionStorage.getItem("user"))
    if (getUser?.username == "rahulKakkar" && getUser?.password == "kakkar@admin") {
      setShowSubmissions(true);
      return;
    }
  }, [])

  const handleAuthentication = () => {
    const username = prompt('Enter username:');
    const password = prompt('Enter password:');

    // Replace with your actual username and password
    const correctUsername = 'rahulKakkar';
    const correctPassword = 'kakkar@admin';

    if (username === correctUsername && password === correctPassword) {
      setShowSubmissions(true);
      sessionStorage.setItem("user", JSON.stringify({ username, password }))
    } else {
      alert('Incorrect username or password.');
    }
  };

  return (
    <div className="container mx-auto my-10 px-4">
      {!showSubmissions ? (
        <div>
          <h1 className="text-2xl font-bold mb-4">Login to View Submissions</h1>
          <button onClick={handleAuthentication} className="bg-blue-500 text-white px-4 py-2 rounded-md mb-4">
            Login
          </button>
        </div>
      ) : (
        <div>
          <h1 className="text-2xl font-bold mb-4">Contact Submissions</h1>
          {submissions.length === 0 ? (
            <p>No submissions yet.</p>
          ) : (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
              {submissions.map(submission => (
                <div key={submission.id} className="border p-4 rounded-lg shadow-md">
                  <h2 className="text-lg font-bold">{submission?.name}</h2>
                  <p className="mb-2">Email: {submission?.email}</p>
                  <p className="mb-2">Mobile: {submission?.mobile}</p>
                  <p className="mb-2">Subject: {submission?.subject}</p>
                  <p className="mb-2">Message: {submission?.message}</p>
                  <p className="text-gray-500">Received: {new Date(submission?.timestamp).toLocaleString()}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ContactSubmissions;
