import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDgWsbSH7mUSniI-gDyvlWiBaYid2pDMDY",
    authDomain: "pg-database-89762.firebaseapp.com",
    projectId: "pg-database-89762",
    storageBucket: "pg-database-89762.appspot.com",
    messagingSenderId: "97406750774",
    appId: "1:97406750774:web:b2f876a1c0b71dbc474897",
    measurementId: "G-34CV6LFE36"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);