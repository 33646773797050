import React from 'react'
import { Icon } from '../common/Icon'


const Breakfast = [
    {
        day: "Monday",
        name: "Beetroot parantha with tea"
    },
    {
        day: "Tuesday",
        name: "Aloo parantha with tea"
    },
    {
        day: "Wednesday",
        name: "Mooli parantha with tea"
    },
    {
        day: "Thursday",
        name: "Poha"
    },
    {
        day: "Friday",
        name: "Palak parantha with tea"
    },
    {
        day: "Saturday",
        name: "Plain parantha with tea"
    },
    {
        day: "Sunday",
        name: "Aloo puri"
    },
]
const Lunch = [
    {
        day: "Monday",
        name: "Rajma, Rice and Roti"
    },
    {
        day: "Tuesday",
        name: "Maah ki daal, Rice and Roti"
    },
    {
        day: "Wednesday",
        name: "White chana, Rice and Roti"
    },
    {
        day: "Thursday",
        name: "Kadi, Rice and Roti"
    },
    {
        day: "Friday",
        name: "Moong daal, Rice and Roti"
    },
    {
        day: "Saturday",
        name: "Black chana, Rice and Roti"
    },
    {
        day: "Sunday",
        name: "Macroni"
    },
]

const Dinner = [
    {
        day: "Monday",
        name: "Aloo nutri and Roti"
    },
    {
        day: "Tuesday",
        name: "Mattar mashroom and Roti"
    },
    {
        day: "Wednesday",
        name: "Aloo Methi, Daal and Roti"
    },
    {
        day: "Thursday",
        name: "Aloo palak or saag and Roti"
    },
    {
        day: "Friday",
        name: "Aloo gajar or patta gobhi and Roti"
    },
    {
        day: "Saturday",
        name: "Seasonal sabji and Roti"
    },
    {
        day: "Sunday",
        name: "Matar panner and Roti"
    },
]
export default function Menu() {
    return (
        <>
            {/* <div className="max-w-7xl mx-auto p-[7%] "> */}
            <div className="max-w-[80%] mx-auto p-[7%] ">
                <div className="w-full flex flex-wrap md:flex-nowrap justify-center  gap-[5%]">
                    <div className="w-full md:w-[45%] flex justify-center">
                        <img
                            src="https://img.freepik.com/free-photo/traditional-azerbaijani-meat-gutab-minced-meat-stuffed-flatbread-served-with-sumac_140725-5219.jpg?w=740&t=st=1714376327~exp=1714376927~hmac=b888f1ecdb416b8a43cf6f8f8fea4f5943b8b47ee624e8e3915a59d3edf91c5d"
                            className="max-w-[450px] max-h-[500px] min-w-[200px] rounded-2xl shadow-2xl"
                            alt=""
                        />
                    </div>
                    <div className="w-full md:w-[55%] ">
                        <div className="mt-3">
                            <p className="text-[#DB4444] lg:text-2xl text-lg ">Breakfast Menu</p>
                            <p
                                className=" lg:text-3xl text-xl"
                                style={{ fontFamily: "Playfair Display", fontWeight: "700" }}
                            >
                                Breakfast made with love
                            </p>
                        </div>

                        {/* <div className="my-[5%] w-full md:w-[420px] max-h-[370px]"> */}
                        <div className="my-[5%] w-full ">
                            {
                                Breakfast.map((data, i) => (
                                    <p className="font-medium lg:text-xl text-sm my-4">
                                        {data?.name}...............
                                        <span className="text-[#DB4444]"> {data?.day}</span>
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </div>

                {/* ====================================================== */}

                <div className="w-full flex flex-wrap-reverse md:flex-nowrap justify-center my-[7%] gap-[5%]">
                    <div className="w-full md:w-[55%] ">
                        <div className="mt-3">
                            <p className="text-[#DB4444] lg:text-2xl text-lg ">Lunch Menu</p>
                            <p
                                className=" lg:text-3xl text-xl"
                                style={{ fontFamily: "Playfair Display", fontWeight: "700" }}
                            >
                                Delight in every bite
                            </p>
                        </div>

                        <div className="my-[5%] w-full">
                        {
                                Lunch?.map((data, i) => (
                                    <p className="font-medium lg:text-xl text-sm my-4">
                                        {data?.name}...............
                                        <span className="text-[#DB4444]"> {data?.day}</span>
                                    </p>
                                ))
                            }
                        </div>
                    </div>

                    <div className="w-full md:w-[45%] flex justify-center">
                        <img
                            src="https://cdn.qafto.com/templates/bakery_cake_ecommerce_1/images/CakeMenu.png"
                            className="max-w-[450px] max-h-[500px] min-w-[200px] rounded-2xl shadow-2xl"
                            alt=""
                        />
                    </div>
                </div>

                {/* ------------------------------------------------------------------------------- */}

                <div className="w-full flex flex-wrap md:flex-nowrap justify-center mb-[7%] gap-[5%]">
                    <div className="w-full md:w-[45%] flex justify-center">
                        <img
                            src="https://img.freepik.com/free-photo/vertical-shot-traditional-indian-paneer-butter-masala-cheese-cottage-curry-black-surface_181624-32001.jpg?t=st=1714376449~exp=1714380049~hmac=e69c76a9c0d0709004a78cd7f6af719484a7291538e1685ccd0d90e0b5529cac&w=740"
                            className="max-w-[450px] max-h-[500px] min-w-[200px] rounded-2xl shadow-2xl"
                            alt=""
                        />
                    </div>
                    <div className="w-full md:w-[55%] flex flex-col justify-center">
                        <div className="mt-3">
                            <p className="text-[#DB4444] lg:text-2xl text-lg ">Dinner Menu</p>
                            <p
                                className=" lg:text-3xl text-xl"
                                style={{ fontFamily: "Playfair Display", fontWeight: "700" }}
                            >
                                Hundreds of flavors under one roof
                            </p>
                        </div>

                        <div className="my-[5%] w-full ">
                        {
                                Dinner.map((data, i) => (
                                    <p className="font-medium lg:text-xl text-sm my-4">
                                        {data?.name}...............
                                        <span className="text-[#DB4444]"> {data?.day}</span>
                                    </p>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
