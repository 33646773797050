import { Menu, Transition } from "@headlessui/react";
import {
  Bars4Icon,
  BuildingStorefrontIcon,
  ChatBubbleBottomCenterIcon,
  HomeIcon,
  IdentificationIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Navbar() {
  return (
    <nav id="header" class="w-full z-30  py-1">
      <div class="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-1 lg:px-6 py-3">
        <div class="">
          <a
            class="flex items-center tracking-wide no-underline hover:no-underline font-bold text-gray-800 text-md lg:text-xl "
            href="/"
          >
            {/* <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50" */}
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"  viewBox="0 0 50 50"
              className="mr-2 w-4 h-4 lg:w-6 lg:h-6"
            >
              <path d="M 24.962891 1.0546875 A 1.0001 1.0001 0 0 0 24.384766 1.2636719 L 1.3847656 19.210938 A 1.0005659 1.0005659 0 0 0 2.6152344 20.789062 L 4 19.708984 L 4 46 A 1.0001 1.0001 0 0 0 5 47 L 18.832031 47 A 1.0001 1.0001 0 0 0 19.158203 47 L 30.832031 47 A 1.0001 1.0001 0 0 0 31.158203 47 L 45 47 A 1.0001 1.0001 0 0 0 46 46 L 46 19.708984 L 47.384766 20.789062 A 1.0005657 1.0005657 0 1 0 48.615234 19.210938 L 41 13.269531 L 41 6 L 35 6 L 35 8.5859375 L 25.615234 1.2636719 A 1.0001 1.0001 0 0 0 24.962891 1.0546875 z M 25 3.3222656 L 44 18.148438 L 44 45 L 32 45 L 32 26 L 18 26 L 18 45 L 6 45 L 6 18.148438 L 25 3.3222656 z M 37 8 L 39 8 L 39 11.708984 L 37 10.146484 L 37 8 z M 20 28 L 30 28 L 30 45 L 20 45 L 20 28 z"></path>
            </svg>
            KakkarPGServices
          </a>
        </div>

        <Menu as="div" className=" text-left">
          <div className="flex items-center">
                <div className="flex mr-4 md:hidden">
                  <a
                    className=" underline rounded-xl text-black" href="tel:6283029505"
                  >
                    Call Us
                  </a>
                </div>
            <Menu.Button className="w-fit outline-none focus:outline-none">
                <label
                  htmlFor="menu-toggle"
                  className="cursor-pointer block md:hidden"
                >
                  <svg
                    className="fill-current text-gray-900"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                    <title>menu</title>
                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
                  </svg>
                </label>
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-[999] right-0 mt-2 w-screen origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1">
                <Menu.Item className="inline-block no-underline hover:text-black hover:underline py-2 px-4">
                  {({ active }) => (
                    <button
                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2`}
                    >
                      <HomeIcon
                        className="mr-2 h-8 w-8"
                        aria-hidden="true"
                      />
                      Home
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item className="inline-block no-underline hover:text-black hover:underline py-2 px-4">
                  {({ active }) => (
                    <a
                      href="/AboutUs"
                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2`}
                    >
                      <IdentificationIcon
                        className="mr-2 h-8 w-8"
                        aria-hidden="true"
                      />
                      About
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item className="inline-block no-underline hover:text-black hover:underline py-2 px-4">
                  {({ active }) => (
                    <a
                      href="/Rooms"
                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2`}
                    >
                      <BuildingStorefrontIcon
                        className="mr-2 h-8 w-8"
                        aria-hidden="true"
                      />
                      Rooms
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item className="inline-block no-underline hover:text-black hover:underline py-2 px-4">
                  {({ active }) => (
                    <a
                      href="/Menu"
                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2`}
                    >
                      <Bars4Icon
                        className="mr-2 h-8 w-8"
                        aria-hidden="true"
                      />
                      Menu
                    </a>
                  )}
                </Menu.Item>
                <Menu.Item className="inline-block no-underline hover:text-black hover:underline py-2 px-4">
                  {({ active }) => (
                    <a
                      href="/Contact"
                      className={`${active ? "bg-violet-500 text-white" : "text-gray-900"
                        } group flex w-full items-center rounded-md px-2 py-2`}
                    >
                      <ChatBubbleBottomCenterIcon
                        className="mr-2 h-8 w-8"
                        aria-hidden="true"
                      />
                      Contact
                    </a>
                  )}
                </Menu.Item>

              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <div
          class="hidden md:flex md:items-center md:w-auto w-full order-3 md:order-1"
          id="menu"
        >
          <nav>
            <ul class="md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0">
              <li>
                <Link
                  to="/"
                  class="inline-block no-underline hover:text-black hover:underline py-2 px-4"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/AboutUs"
                  class="inline-block no-underline hover:text-black hover:underline py-2 px-4"
                >
                  About
                </Link>
              </li>
              <li>
                <Link
                  to="/Rooms"
                  class="inline-block no-underline hover:text-black hover:underline py-2 px-4"
                >
                  Rooms
                </Link>
              </li>
              <li>
                <Link
                  to="/Menu"
                  class="inline-block no-underline hover:text-black hover:underline py-2 px-4"
                >
                  Menu
                </Link>
              </li>
              <li>
                <Link
                  to="/Contact"
                  class="inline-block no-underline hover:text-black hover:underline py-2 px-4"
                >
                  ContactUs
                </Link>
              </li>
              <li>
                <a className="bg-gray-800 px-4 py-2 rounded-xl text-white" href="tel:6283029505">Call Us</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
