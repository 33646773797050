import React, { useState } from 'react'
import { db } from '../firebase/Firebase';
import { addDoc, collection } from 'firebase/firestore';
import toast from 'react-hot-toast';

function Push() {

    const [formData, setFormData] = useState({
        title: '',
        images: '',
        description: '',
        price: 0,
        category: '',
        image: [],
        type: ''
      });
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          // Add product data to Firestore collection
          const docRef = await addDoc(collection(db, 'products'), formData);
          console.log('Product added with ID: ', docRef.id);
          toast.success("Data submitted")
        } catch (error) {
          console.error('Error adding product: ', error);
        }
      };
    
      const handleChange = (e) => {
        const value = e.target.name === 'image' ? e.target.value.split(',') : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
      };


  return (
    <div className='felx flex-col my-6'>
    <h2>Add Product</h2>
    <form onSubmit={handleSubmit} className='flex flex-col max-w-md mx-auto'>
      <label>Title</label>
      <input type="text" name="title" value={formData.title} onChange={handleChange} />

      <label>Images</label>
      <input type="text" name="image" value={formData.images} onChange={handleChange} />

      <label>Description</label>
      <textarea name="description" value={formData.description} onChange={handleChange} />

      <label>Price</label>
      <input type="number" name="price" value={formData.price} onChange={handleChange} />

      <label>Category</label>
      <input type="text" name="category" value={formData.category} onChange={handleChange} />

      <label>Type</label>
      <input type="text" name="type" value={formData.type} onChange={handleChange} />

      {/* Add inputs for image and type if needed */}

      <button className=' mt-4 bg-blue-600 text-white rounded-xl p-2' type="submit">Submit</button>
    </form>
  </div>
  )
}

export default Push