import React from 'react'
import Carousel from 'react-multi-carousel';
import { CustomLeftArrow, CustomRightArrow } from './SingleRoom';


const Service = [
    {
        img: "https://images.unsplash.com/photo-1590756254933-2873d72a83b6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name: "Air Conditioner"
    },
    {
        img: "https://images.unsplash.com/photo-1606421753414-8d165c9d48e5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name: "Wifi"
    },
    {
        img: "https://plus.unsplash.com/premium_photo-1664372899525-d99a419fd21a?q=80&w=1988&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name: "Washing Machine"
    },
    {
        img: "https://images.unsplash.com/photo-1590756254933-2873d72a83b6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        name: "Geyser Service"
    },
]
export default function Services() {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 1, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 1, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };
    return (
        <div className='my-8'>
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                // autoPlay={true}
                className=""
                autoPlaySpeed={1000}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                customLeftArrow={<CustomLeftArrow />}
                customRightArrow={<CustomRightArrow />}
            >
                {
                    Service.map((data) => (
                        <div className='lg:group relative'>
                            <img src={data?.img} className='aspect-[1.2] w-full object-cover' />
                            <p className='bg-[rgba(0,0,0,.3)] tracking-widest text-gray-200 w-full h-full flex items-center justify-center text-xl font-bold absolute top-0 group-hover:opacity-100 lg:opacity-0 duration-700'>{data.name}</p>
                        </div>
                    ))
                }
            </Carousel>
        </div>
    )
}
