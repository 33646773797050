import React from 'react';

function CarouselItem({ id, imageSrc, productName, productLink }) {
    return (
        <>
            <div className="carousel-item  h-[30vh] md:h-[50vh]" >
                <div className=" h-full w-full mx-auto flex pt-6 md:pt-0 md:items-center bg-no-repeat bg-contain md:bg-cover  bg-center" style={{ backgroundImage: `url('${imageSrc}')` }}>
                    <div className="container mx-auto">
                        <div className="flex flex-col w-full lg:w-1/2 md:ml-16 items-center md:items-start px-6 tracking-wide">
                            {/* <p className="text-white text-2xl my-4 bg-gray-800 px-2 rounded-xl py-2">{productName}</p> */}
                            {/* <a className="text-xl inline-block no-underline border-b border-gray-600 leading-relaxed text-white bg-gray-800 px-2 rounded-xl py-2 hover:text-white hover:border-black" href={productLink}>view product</a> */}
                        </div>
                    </div>
                </div>
            </div>
            <label htmlFor={`carousel-${id + 2}`} className="prev control-1 w-10 h-10 ml-2 md:ml-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 left-0 my-auto">‹</label>
            <label htmlFor={`carousel-${id}`} className="next control-1 w-10 h-10 mr-2 md:mr-10 absolute cursor-pointer hidden text-3xl font-bold text-black hover:text-white rounded-full bg-white hover:bg-gray-900 leading-tight text-center z-10 inset-y-0 right-0 my-auto">›</label>
        </>
    );
}

export default CarouselItem;
