import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './common/Navbar';
import Home from './pages/Home';
import Footer from './common/Footer';
import ContactUs from './pages/ContactUs';
import Rooms from './pages/Rooms';
import About from './pages/About';
import SingleRoom from './pages/SingleRoom';
import { Toaster } from 'react-hot-toast';
import Push from './pages/Push';
import ContactSubmissions from './pages/AdminContactUs';
import Menu from './pages/Menu';

const App = () => {
  return (
    <Router>
      <div className="App font-work_sans"> {/* Add a meaningful class for styling */}
        <Navbar />
        <main>
          <Routes>
            <Route exact path="/" element={<Home />} />
          <Route exact path="/AboutUs" element={<About />} />
          <Route exact path="/Rooms" element={<Rooms />} />
          <Route exact path="/Rooms/:productId" element={<SingleRoom />} />
          <Route exact path="/contact" element={<ContactUs />} />
          <Route exact path="/admin/contact" element={<ContactSubmissions />} />
          <Route exact path="/Push" element={<Push />} />
          <Route exact path="/Menu" element={<Menu />} />
          </Routes>
        </main>
        <Footer />
        <Toaster 
         position="bottom-right"
         reverseOrder={false} />
      </div>
    </Router>
  );
};

export default App;
